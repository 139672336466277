import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const FooterWrapper = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  left: 0;
  bottom: 0;
  //padding: 0.8rem 0;
  width: 100%;
  color: white;
  margin-top: 8rem;
  background: var(--primary-color);

  div {
    flex: 1;
    text-align: center;
  }

  a:link,
  a:visited {
    color: white;
  }
`

const Footer = () => (
  <FooterWrapper>
    <div>
      <small>© {new Date().getFullYear()} - Levert av Cars Software</small>
    </div>
    <div>
      <Link to="/personvern">Personvern</Link>
    </div>
  </FooterWrapper>
)

export default Footer
