import styled from 'styled-components'
import { device } from '../device'

const breakpoint = device.laptopL
const brakpointLaptop = device.laptopMAX
const Mobil = device.tabletMaxL
export const NavBar = styled.header`
  display: none;
  @media ${breakpoint} {
    display: inline-block;
    position: fixed;
    width: 100%;
    height: 80px;
    background: white;
    z-index: 2;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  }
`
export const NavBarSubPage = styled.header`
  display: none;
  @media ${breakpoint} {
    display: block;
    position: fixed;
    width: 100%;
    height: 80px;
    top: 0px;
    background: white;
    z-index: 2;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  }
`
export const NavBarContainer = styled.nav`
  margin-left: auto;
  margin-right: auto;
  max-width: 1448px;
  display: flex;
  align-items: center;
  height: 100%;
`
export const NavBarBrand = styled.h1`
  display: block;
  margin-right: 3rem;
  min-width: 200px;
  max-width: 400px;

  a {
    font-size: 1.1rem;
    font-weight: 700;
    color: var(--primary-color);
    text-decoration: none;
    @media ${breakpoint} {
      font-size: 1.5rem;
    }
  }
`

export const NavbarHedingImg = styled.div`
  padding-top: 15px;
`

export const NavBarBrandImage = styled.div`
  display: block;
  margin-right: 3rem;
  min-width: 200px;
  max-width: 400px;

  a {
    font-size: 1.1rem;
    font-weight: 700;
    color: var(--primary-color);
    text-decoration: none;
    @media ${breakpoint} {
      font-size: 1.5rem;
    }
  }
`
export const NavBarLeft = styled.ul`
  margin: 0;
  padding: 0;
  padding-right: 15px;
  width: 100%;
  list-style-type: none;
  flex: 4;
  display: flex;
  justify-content: flex-start;
  color: white;
  font-weight: 700;
  font-size: 1rem;
  li {
    margin-right: 1rem;
  }
`
export const NavBarRight = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
  flex: 2;
  color: white;
  font-weight: 700;
  font-size: 1.1rem;
  li {
    margin-left: 1.5rem;
  }
`

export const SubNavBarRight = styled.ul`
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
  flex: 2;
  margin-left: auto;
  margin-right: auto;
  max-width: 1448px;
  height: 100%;
  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    a {
      max-width: none;
    }
  }
`
export const SubNavBarItem = styled.li`
  margin-bottom: 0;
  font-weight: 700;
  font-size: 0.9rem;
  white-space: nowrap;
  a {
    color: var(--primary-color);
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
  }
  a.jobehososs {
    font-size: 0.9rem;
    color: white !important;
  }
  button {
    font-weight: 700;
    font-size: inherit;
    color: var(--primary-color);
    background: transparent;
    border: none;
    &:hover {
      cursor: pointer;
    }
  }
`

export const NavBarItem = styled.li`
  margin-bottom: 0;
  font-weight: 700;
  font-size: 1.1rem;
  a {
    color: var(--primary-color);
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
  }
  a.jobehososs {
    font-size: 0.9rem;
    color: white !important;
  }
  button {
    font-weight: 700;
    font-size: inherit;
    color: var(--primary-color);
    background: transparent;
    border: none;
    &:hover {
      cursor: pointer;
    }
  }
`
export const NavDropDownContent = styled.li`
  background-color: white;
  min-width: 160px;
  padding: 5px;
  z-index: 2;
  a {
    color: var(--primary-color);
    text-decoration: none;
    z-index: 2;
    padding: 5px;
    display: grid;
    grid-template-columns: 1fr;
    border-bottom: 1px solid var(--primary-color);

    &:hover {
      cursor: pointer;
      background-color: whitesmoke;
    }
  }
  @media ${breakpoint} {
    position: absolute;
    min-width: 160px;
    padding: 15px 16px;
    z-index: 1;
  }
`
export const NavDropDown = styled.div`
  margin-bottom: 0;
  font-weight: 700;
  font-size: 1.1rem;
  z-index: 1;
  color: var(--primary-color);
  cursor: pointer;
`
export const NavBarMobile = styled.header`
  position: relative;
  width: 100%;
  height: 80px;
  background: white;
  z-index: 2;

  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  @media ${breakpoint} {
    display: none;
  }
`

export const NavBarMobileContainer = styled.nav`
  margin-left: auto;
  margin-right: auto;
  max-width: 1348px;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
`
export const NavBarMenuButton = styled.button`
  border: none;
  background: white;
  &:active {
    background: var(--primary-color);
    div {
      background: white;
    }
  }
  div {
    width: 35px;
    height: 5px;
    background: var(--primary-color);
    margin: 6px auto;
  }
`
export const NavMobile = styled.ul`
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  //top: 80px;
  left: 0;
  width: 100%;
  background: white;
  z-index: 1;
  transition: all 0.25s ease-out;
  &.isOpen {
    transform: translateY(0%);
  }
  &.isClosed {
    transform: translateY(-200%);
  }
  li {
    a {
      display: block;
      padding: 1rem 1.5rem;
      border-bottom: 1px solid #dddddd;
    }
  }
`
export const NavBarPrimary = styled.div`
  display: none;
  @media ${breakpoint} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    background: var(--primary-color);
    z-index: 1;
    a {
      max-width: 50px;
      color: white;
      justify-content: end;
    }
  }
`
export const NavbarCenterElement = styled.ul`
  background-color: var(--primary-white);
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1348px;

  a {
    margin: auto;
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 700;
    font-size: 1.1rem;
  }
`
export const NavBarContainerCenter = styled.nav`
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  background: var(--primary-white);
`
export const ScrollNabareEL = styled.div`
  cursor: pointer;
`
export const ButtonBilxtra = styled.div`
  background-color: white;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 2px 9px;
  cursor: pointer;
  &:hover {
    background-color: #e8e8e8;
    box-shadow: 0px 1px 5px 1px rgba(26, 81, 160, 0.45);
  }
  @media ${Mobil} {
    background-color: white;
    box-shadow: none;
    border-radius: none;
    padding: none;
    &:hover {
      background-color: red;
      box-shadow: none;
    }
   
  
  }

  @media ${brakpointLaptop} {
    background-color: white;
    box-shadow: none;
    border-radius: none;
    padding: 2px;
    &:hover {
      background-color: white;
      box-shadow: none;
    }


`
